import styled from "styled-components";
import logo from './logo.svg';
import './App.css';
import {
	 SwayzeCreateForm 
} from './ui-components';

import {Amplify} from "aws-amplify";
//import awsmobile from "./aws-exports.js";
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://csgi4zcvavhe3hhjm5lydhxyqy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-533tvkzbbjeanbr6cp6izu275q"
}
Amplify.configure(awsmobile);

const Citate = styled.div`
  font-size: xx-small;
`;

function App() {

  return (
    <div className="App">
      <header className="App-header">
	  <img src="https://upload.wikimedia.org/wikipedia/commons/6/63/Patrick_Swayze.jpg" />
	  <Citate>photo by Alan Light</Citate>
	  <SwayzeCreateForm />
      </header>
    </div>
  );
}

export default App;
